import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { FileDrop } from 'react-file-drop';
import { beautifyDate, sleep } from '../../utils/helpers';
import { runWorkflow } from '../../utils/requests';

const ViewRunCsvModal = observer((props) => {
  const {
    open, onClose,
  } = props;

  const [tab] = useState(String.fromCharCode(9));
  const [comma] = useState(',');
  const [csvText, setCsvText] = useState('');
  const [logs, setLogs] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(true);

  const reader = new FileReader();
  const fileInputRef = useRef(null);

  const addToLogs = (log) => {
    setLogs((prevLogs) => [...prevLogs, log]);
  };

  const cleanData = (headers, rowsRaw, delimiter) => {
    let log;
    const rowsClean = [];
    for (let i = 1; i < rowsRaw.length; i += 1) {
      log = {};
      const csvLine = i + 1;
      // add raw row number as first element (adding 1 to account for 0 indexed array)
      const val = [csvLine].concat(rowsRaw[i].split(delimiter));
      if (val.length !== headers.length) {
        log = {
          text: `Omitting Line ${csvLine}: [${headers.length}] inputs expected, found [${val.length}] - ${val.join(', ')}`,
          date: beautifyDate(Date.now()),
          error: true,
        };
        addToLogs(log);
      } else {
        rowsClean.push(val);
      }
    }
    return rowsClean;
  };

  const getData = (rowsRaw, delimiter) => {
    // add row number as first element of headers array
    const headers = ['#'].concat(rowsRaw[0].split(delimiter).map((val) => val.toUpperCase()));
    const rowsClean = cleanData(headers, rowsRaw, delimiter);
    return { headers, rowsClean };
  };

  const getRows = (strRaw) => {
    const csvStrLF = strRaw.replaceAll('\r', '');
    const rowsRaw = csvStrLF.split('\n');
    return rowsRaw;
  };

  const csvToArray = () => {
    const strRaw = csvText;

    let delimiter;

    const rowsRaw = getRows(strRaw);
    if (!rowsRaw.length) {
      return [];
    }
    if (rowsRaw[0].includes(tab)) {
      delimiter = tab;
    } else {
      delimiter = comma;
    }

    const { headers, rowsClean } = getData(rowsRaw, delimiter);

    const arr = [];
    for (let i = 0; i < rowsClean.length; i += 1) {
      const rawRowNum = rowsClean[i][0]; // get the raw row number from first element of array
      const obj = { rawRowNum };
      // we skip the first element of the headers array as it is its row number
      for (let j = 1; j < headers.length; j += 1) {
        obj[headers[j]] = rowsClean[i][j];
      }
      arr.push(obj);
    }
    return arr;
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    setCsvText(e);
    setConfirmed(false);
    setLoading(true);
    if (e === '') {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  };

  const handleFileInput = (files) => {
    if (files[0]) {
      reader.readAsText(files[0]);
    }
  };

  const onFileInputChange = (event) => {
    const { files } = event.target;
    handleFileInput(files);
  };

  reader.onload = () => {
    const res = reader.result;
    setCsvText(res);
    setConfirmed(false);
    setLoading(true);
    if (res === '') {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  };

  const handleClose = () => {
    onClose();
    setCsvText('');
    setEmpty(true);
    setConfirmed(false);
    setLogs([]);
    setLoading(true);
  };

  const handleRun = async (runWorkflows = true) => {
    if (runWorkflows) {
      setConfirmed(true);
    }

    setLogs([]);
    const csvArray = csvToArray();
    let log;
    for (let i = 0; i < csvArray.length; i += 1) {
      if (i > 0) {
        // add small sleep after first iteration to not hang browser if large CSV used
        // eslint-disable-next-line no-await-in-loop
        await sleep(250);
      }

      log = {};
      const {
        rawRowNum,
        WORKFLOW,
        VERSION,
        ...parameters
      } = csvArray[i];

      if (!runWorkflows) {
        const arrParams = Object.entries(parameters).map((entry) => `${entry[0]}: ${entry[1]}`);
        const displayVersion = VERSION || 'DEFAULT';
        const displayWorkflow = (WORKFLOW) ? `Workflow: ${WORKFLOW}` : 'ERROR: NO WORKFLOW SPECIED';
        log = {
          text: `Row ${rawRowNum}: ${displayWorkflow} (${displayVersion}) - ${arrParams.join(' | ')}`,
          date: beautifyDate(Date.now()),
          error: !WORKFLOW,
        };
        addToLogs(log);
        // eslint-disable-next-line no-continue
        continue;
      }

      // eslint-disable-next-line no-await-in-loop
      const res = await runWorkflow(
        WORKFLOW,
        VERSION,
        parameters,
      );

      const csvLineMsg = `Workflow run (Line ${rawRowNum})`;
      if (res.status === 200) {
        log = {
          text: `${csvLineMsg} - Successfully Started`,
          date: beautifyDate(Date.now()),
        };
        addToLogs(log);
      } else {
        log = {
          text: `${csvLineMsg} - Failed - ${res.data.msg}`,
          date: beautifyDate(Date.now()),
          error: true,
        };
        addToLogs(log);
      }
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => { if (reason !== 'backdropClick') { onClose(event, reason); } }}
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
    >
      <DialogTitle style={{ borderBottom: '1px solid' }}>
        Run CSV File
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              Select a file or enter text below - a header row is necessary.
              Entries can be separated using commas or tabs.
            </div>
            <br />
            <div>
              The header row should be as follows:
              <ul>
                <li>WORKFLOW - required, name of the workflow to run</li>
                <li>VERSION - optional, use if you want to specify a specific version to run</li>
                <li>@PARAM1, @PARAM2, etc. - required, match inputs for the workflow to run</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={9}>
            <AceEditor
              mode="text"
              theme="github"
              width="100%"
              readOnly={confirmed && loading}
              focus={!confirmed || !loading}
              wrapEnabled
              value={csvText}
              maxLines={12}
              minLines={12}
              placeholder="Comma or tab separated data"
              name="csvText"
              id="csvText"
              tabSize={2}
              style={{
                border: '1px solid lightgray',
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <div
              style={{
                gridArea: 'drop',
                position: 'relative',
                border: '1px dashed #404040',
                borderRadius: '0.3rem',
                color: '#404040',
                width: '100%',
                height: '100%',
              }}
            >
              <FileDrop
                onDrop={handleFileInput}
                onTargetClick={onTargetClick}
              >
                <CloudUploadOutlinedIcon fontSize="large" />
                <div>Drag and drop</div>
                <div>or</div>
                <div>click to select a file to fill the textbox.</div>
              </FileDrop>
            </div>
            <input
              hidden
              type="file"
              accept="text/csv"
              ref={fileInputRef}
              onChange={onFileInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <List
              style={{
                gridArea: 'footer',
              }}
            >
              {logs.map((action) => (
                <ListItem value={action} key={action.text}>
                  <ListItemText primary={action.text} secondary={action.date} primaryTypographyProps={action.error ? { color: 'error' } : { color: 'primary' }} />
                </ListItem>
              ))}

              <ListItem>
                {(loading && confirmed) && <CircularProgress />}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{
        display: 'flex', justifyContent: 'center', borderTop: '1px solid', marginTop: 20, padding: 16,
      }}
      >
        {
          (!confirmed) ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                autoFocus
                onClick={(() => handleRun(true))}
                disabled={empty}
                style={{ margin: '0 5px 0 5px', width: 100 }}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={(() => handleRun(false))}
                disabled={empty}
                style={{ margin: '0 5px 0 5px', width: 100 }}
              >
                Test
              </Button>
              <Button variant="contained" color="secondary" onClick={handleClose} style={{ margin: '0 5px 0 5px', width: 100 }}>
                Cancel
              </Button>
            </div>
          ) : (
            <div>
              <Button variant="contained" color="secondary" disabled={loading} onClick={handleClose} style={{ margin: '0 5px 0 5px', width: 100 }}>
                Close
              </Button>
            </div>
          )
       }
      </DialogActions>
    </Dialog>
  );
});

export default ViewRunCsvModal;
